import { useRouter } from 'next/router'
import { useEffect } from 'react'
// import { withAuthServerSideProps } from 'src/hoc/withAuth'

const Issue = () => {
  const router = useRouter()
  const { issueId } = router.query

  useEffect(() => {
    if (issueId) {
      void router.push(`/${issueId.toString()}/edit`)
    }
  }, [issueId, router])

  return null
}

// export const getServerSideProps = withAuthServerSideProps()

export default Issue
